import React, { useState, useEffect } from 'react'
import StandardButton from '../shared/standardButton'
import { useAnalyticExportApi, useAnalyticExportContext } from '../../contexts/analyticExportContext'

const CreateAnalystButton = () => {
  const { createAnalyticExport } = useAnalyticExportApi()
  const { analyticExport } = useAnalyticExportContext()
  const [active, setActive] = useState(false)
  useEffect(() => { setActive(analyticExport.sample_collection_ids.length > 0) }
    , [analyticExport.sample_collection_ids])

  return (
    <StandardButton
      buttonText={'Create New Analyst'}
      btnClass={active ? 'btn-primary' : 'btn-disabled'}
      onClickHandler={active ? () => createAnalyticExport(analyticExport) : () => {}}
      toolTipMessage={ !active && 'Requires Selected Sample Collections' } />
  )
}

export default CreateAnalystButton
