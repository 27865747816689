import React, { useMemo, useReducer, useContext, useEffect, createContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { getAnalyticExports, createAnalyticExport, deleteAnalyticExport } from '../api/lab/analyticExportApi'
import { openInNewTab } from '../utilities/helpers'
// Actions
const INIT = "INIT"
const TOGGLE = 'TOGGLE'
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_NESTED_STATE = 'UPDATE_NESTED_STATE'

// Initial States
const initialState = {
  analyticExports: [],
  analyticExport: {
    guideline_ids: [],
    sample_collection_ids: [],
  },
  loading: false,
}

// Reducer
const analyticExportReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return { ...state, analyticExports: action.analyticExports }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case TOGGLE:
      return { ...state, [action.field]: !state[action.field] }
    case UPDATE_NESTED_STATE:
      return { ...state, [action.field]: { ...state[action.field], [action.nestedField]: action.value } }
    default:
      return state
  }
}

// context
const AnalyticExportContext = createContext(initialState)
const AnalyticExportApiContext = createContext({})

// provider
export const AnalyticExportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(analyticExportReducer, initialState)
  const { projectId } = useParams()

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const toggleField = field => dispatch({ type: TOGGLE, field })

    const updateNestedState = (field, nestedField) => value => dispatch({ type: UPDATE_NESTED_STATE, field, nestedField, value })

    return { updateState, toggleField, updateNestedState }
  }, [])

  api.createAnalyticExport = async (analyticExports) => {
    const response = await createAnalyticExport(projectId, analyticExports)
    dispatch({ type: UPDATE_STATE, field: 'analyticExports', value: [...state.analyticExports, response] })
    dispatch({ type: UPDATE_STATE, field: 'analyticExport', value: { guideline_ids: [], sample_collection_ids: [] } })
    openInNewTab(`/projects/${projectId}/analytic-exports/${response.id}`)
  }

  api.deleteAnalyticExport = async (id) => {
    await deleteAnalyticExport(projectId, id)
    dispatch({ type: UPDATE_STATE, field: 'analyticExports', value: state.analyticExports.filter(analyticExport => analyticExport.id !== id) })
  }

  const initializeFunc = useCallback(async () => {
    try {
      const analyticExports = await getAnalyticExports(projectId);
      dispatch({ type: INIT, analyticExports: analyticExports });
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  }, [projectId, dispatch])

  useEffect(() => {
    void initializeFunc()
  }, [])

  return (
    <AnalyticExportContext.Provider value={state}>
      <AnalyticExportApiContext.Provider value={api}>
        {children}
      </AnalyticExportApiContext.Provider>
    </AnalyticExportContext.Provider>
  )
}

// Custom hooks
export const useAnalyticExportContext = () => useContext(AnalyticExportContext)
export const useAnalyticExportApi = () => useContext(AnalyticExportApiContext)
