import React, { useState } from 'react'
import LabBreadCrumbs from './labBreadCrumbs'
import Widget from '../shared/widget'
import SampleCollectionTable from './sampleCollections/sampleCollectionTable'
import { SampleCollectionProvider } from '../../contexts/sampleCollectionContext'
import { AnalyticExportProvider } from '../../contexts/analyticExportContext'
import AnalyticExportTable from './analyticExports/analyticExportTable'

const LabAnalytics = ({ project, routes }) => {
  const [widgetUrl, setWidgetUrl] = useState('')

  return (
    <div className="px-4 w-full h-full">
      <LabBreadCrumbs project={project} routes={routes} extension={'sample-collections'} />
      <div className='w-full h-full flex flex-row'>
        <div className="pr-4 pl-4 h-full lg:w-1/3">
          <a href={widgetUrl} target="_blank" rel="noreferrer noopener" className="w-full btn btn-primary font-semibold cursor-pointer block text-center lg:rounded-t-md lg:rounded-b-none">
            Open Map
          </a>
          <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles='lg:p-0 w-full h-[calc(100%-37px)]' widgetChangeCallback={setWidgetUrl} />
        </div>
        <div className='w-2/3'>
          <div className={`w-full px-2 py-4 `}>
            <h2 className="text-2xl font-bold">PROJECT: {project?.name} </h2>
          </div>
          <SampleCollectionProvider>
            <AnalyticExportProvider>
              <SampleCollectionTable />
              <AnalyticExportTable />
            </AnalyticExportProvider>
          </SampleCollectionProvider>
        </div>
      </div>
    </div>
  )
}

export default LabAnalytics
