import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Project from "../contexts/projectContext"
import { PortfolioProvider } from "../contexts/portfolioContext"
import { TrackerProvider } from "../contexts/trackerContext"
import { NoteFilterProvider } from "../contexts/noteFilterContext"
import { ReportProvider } from "../contexts/reportContext"
import { ChecklistProvider } from '../contexts/checklistContext'
import Notes from "./projects/notes"
import Note from "./projects/note"
import Permits from "./projects/permits"
import Permit from "./projects/permits/permit"
import LandingPage from "./shared/landingPage"
import Export from "./projects/exportContext"
import PortfolioProject from "./projects/project"
import HistoricalAssessment from "./historicalAssessment"
import Tracker from "./trackers"
import TrackerChangeLog from "./trackers/changeLog"
import Report from "./reports"
import Checklist from './checklists'
import ReportsExport from "./reports/reports-export"
import BoreholeScatterChart from './projects/notes/charts/boreholeScatterChart'
import PhotoGallery from './photoGallery'
import AiProjectDocument from './ai/projectDocuments'
import { PhotoProvider } from '../contexts/photoContext'
import Timesheet from "./timesheet"
import UserLandingPage from "./user"
import DocumentLog from "./documentLog"
import { DocumentLogProvider } from "../contexts/documentLogContext"
import DocumentBuilder from "./documentBuilder"
import { DocumentBuilderProvider } from "../contexts/documentBuilderContext"
import EditDocumentBuilder from "./documentBuilder/edit"
import SafetyTicket from './safetyTicket'
import { UserProvider } from '../contexts/userContext'
import LabAnalytics from './labAnalytics'
import TestResults from './labAnalytics/testResults'

export default function MainProject({ project, routes }) {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path='/projects/:projectId/notes/:noteId'
            element={
              <Project project={project} routes={routes}>
                <NoteFilterProvider>
                  <Notes>
                    <Note />
                  </Notes>
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/notes'
            element={
              <Project project={project} routes={routes}>
                <NoteFilterProvider>
                  <Notes />
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/permits/:permitId'
            element={
              <Project project={project} routes={routes}>
                <Permit routes={routes} />
              </Project>
            }
          />
          <Route path='/projects/:projectId/permits'
            element={
              <Project project={project} routes={routes}>
                <Permits />
              </Project>
            }
          />
          <Route path='/projects/:projectId/landing_page'
            element={
              <Export project={project} routes={routes}>
                <LandingPage project={project} routes={routes} />
              </Export>
            }
          />
          <Route path='/projects/:projectId/historical-assessment' element={<HistoricalAssessment project={project} />} />
          <Route path='/projects/:projectId/trackers/:trackerSlug/change-log'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <TrackerProvider>
                    <TrackerChangeLog routes={routes} />
                  </TrackerProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/trackers/:trackerSlug'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <TrackerProvider>
                    <Tracker routes={routes} />
                  </TrackerProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/checklists/:checklistSlug'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <ChecklistProvider>
                    <Checklist routes={routes} />
                  </ChecklistProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/reports/:reportSlug'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <ReportProvider>
                    <Report routes={routes} />
                  </ReportProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/reports-export'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <ReportsExport routes={routes} />
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/document-log' element={
            <Project project={project} routes={routes}>
              <DocumentLogProvider>
                <DocumentLog />
              </DocumentLogProvider>
            </Project>
          } />
          <Route path='/projects/:projectId/document-builders/:templateSlug/document-log/new' element={
            <Project project={project} routes={routes}>
              <DocumentBuilderProvider>
                <EditDocumentBuilder />
              </DocumentBuilderProvider>
            </Project>
          } />
          <Route path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId' element={
            <Project project={project} routes={routes}>
              <DocumentBuilderProvider>
                <DocumentBuilder />
              </DocumentBuilderProvider>
            </Project>
          } />
          <Route path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId/edit' element={
            <Project project={project} routes={routes}>
              <DocumentBuilderProvider>
                <EditDocumentBuilder />
              </DocumentBuilderProvider>
            </Project>
          } />
          <Route path='/projects/:projectId'
            element={
              <Project project={project} routes={routes}>
                <NoteFilterProvider>
                  <PortfolioProject project={project} routes={routes} />
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route path='/projects/:projectId/borehole_depth_chart'
            element={
              <Project project={project} routes={routes}>
                <BoreholeScatterChart routes={routes} />
              </Project>
            }
          />
          <Route path='/projects/:projectId/photo_gallery'
            element={
              <PhotoProvider>
                <PortfolioProvider>
                  <PhotoGallery />
                </PortfolioProvider>
              </PhotoProvider>
            }
          />
          <Route path='/projects/:projectId/ai/documents'
            element={
              <Project project={project} routes={routes}>
                <PortfolioProvider>
                  <AiProjectDocument routes={routes} />
                </PortfolioProvider>
              </Project>
            }
          />
          <Route path='projects/:projectId/sample-collections' element={<LabAnalytics project={project} routes={routes}/>} />
          <Route path='projects/:projectId/analytic-exports/:analyticExportId' element={<TestResults project={project} routes={routes}/>} />
          <Route path='/timesheets' element={<Timesheet />} />
          <Route path='/users/landing_page' element={<UserLandingPage />} />
          <Route path='/safety_tickets' element={<SafetyTicket />} />
        </Routes>
      </UserProvider>
    </Router >
  )
}
