import React from 'react'
import { useAnalyticExportApi } from '../../../contexts/analyticExportContext'
import { openInNewTab } from '../../../utilities/helpers'
import { useParams } from 'react-router-dom'

const AnalyticExportRow = ({ analyticExport }) => {
  const { deleteAnalyticExport } = useAnalyticExportApi()
  const lot_numbers = analyticExport?.sample_collections.map((sample_collection) => sample_collection.lot_number).join(', ')
  const guidelines = analyticExport?.guidelines.map((guideline) => guideline.name).join(', ')
  const { projectId } = useParams()
  return (
    <>
      <div className='p-2 text-center border border-gray-300'> {analyticExport?.updated_at}</div>
      <div className='p-2 text-center border border-gray-300'> {lot_numbers}</div>
      <div className='p-2 text-center border border-gray-300'> {guidelines}</div>
      <div className='p-2 text-center border border-gray-300'>
        <button
          className='btn btn-orange cursor-pointer text-center w-fit h-fit font-semibold'
          onClick={() => openInNewTab(`/projects/${projectId}/analytic-exports/${analyticExport.id}`)}>
          Edit
        </button>
      </div>
      <div className='p-2 text-center border border-gray-300'>N/A</div>
      <div className='p-2 text-center border border-gray-300'>
        <button
          className='btn btn-danger cursor-pointer text-center w-fit h-fit font-semibold'
          onClick={() => deleteAnalyticExport(analyticExport.id)}
        >
          Delete
        </button></div>
    </>
  )
}

export default AnalyticExportRow
