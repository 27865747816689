export const aiHeaders = [
  { title: 'Name', description: 'Name of the document' },
  { title: 'Status', description: 'The status of the document. Processing is converting to json, prompting is analysis by OpenAI, finished signifies that the document has finished being analyzed.' },
  { title: 'Updated At', description: 'Updated At' },
  { title: 'AI Ready', description: 'Shows upload for AI has been used' },
]

export const safetyTicketHeaders = [
  { title: 'Name', description: 'Name of Ticket' },
  { title: 'Required', description: 'Is the ticket required?' },
  { title: 'Ticket Image', description: '' },
  { title: 'Expiry Date', description: '' },
  { title: 'Created By', description: '' }
]

export const sampleCollectionHeaders = [
  { title: 'Select', description: '' },
  { title: 'Lot Number#', description: '' },
  { title: 'Sample Start Date', description: '' },
  { title: 'Sample End Date', description: ']' },
  { title: 'Sample Event Type', description: '' },
  { title: 'Lab', description: '' },
  { title: 'Lab Report Date', description: '' },
  { title: 'Lab Report CSV', description: '' },
  { title: 'Lab Report PDF', description: '' },
  { title: 'View Results', description: '' },
  { title: 'Delete Report', description: '' }
]

export const analysisHeaders = [
  { title: 'Date', description: '' },
  { title: 'Sample Collection Lot Numbers', description: '' },
  { title: 'Guidelines', description: '' },
  { title: 'Edit', description: '' },
  { title: 'Export', description: '' },
  { title: 'Delete', description: '' }
]
