import React, { useState } from 'react'
import Widget from '../../shared/widget'
import LabBreadCrumbs from '../labBreadCrumbs'
import { useParams } from 'react-router-dom'

const TestResults = ({ project, routes }) => {
  const [widgetUrl, setWidgetUrl] = useState('')
  const { analyticExportId } = useParams()
  return (
    <div className="px-4 w-full h-full">
      <LabBreadCrumbs project={project} routes={routes} extension={`analytic-exports/${analyticExportId}`} />
      <div className='w-full h-full flex flex-row'>
        <div className="pr-4 pl-4 h-full lg:w-1/3">
          <a href={widgetUrl} target="_blank" rel="noreferrer noopener" className="w-full btn btn-primary font-semibold cursor-pointer block text-center lg:rounded-t-md lg:rounded-b-none">
            Open Map
          </a>
          <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles='lg:p-0 w-full h-[calc(100%-37px)]' widgetChangeCallback={setWidgetUrl} />
        </div>
        <div className='w-2/3'>
          <div className={`w-full px-2 py-4`}>
            <h2 className="text-2xl font-bold">PROJECT: {project?.name}</h2>
          </div>
          <div className={`w-full px-2 py-4 bg-gray-200 flex justify-center`}>
            <h2 className="text-2xl font-bold">Test Results</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestResults
