import  API, { simpleGet, simplePost } from "../apiConfig"
const csrfToken = document.querySelector('[name=csrf-token]').content

export const getAnalyticExports = (projectId) =>
  simpleGet(`/projects/${projectId}/analytic-exports.json`)

export const createAnalyticExport = (projectId, params) =>
  simplePost(`/projects/${projectId}/analytic-exports`, params)

export const deleteAnalyticExport = (projectId, id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/projects/${projectId}/analytic-exports/${id}`)
    .then(res => res.data)
}
