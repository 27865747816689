import React from 'react'
import { sampleCollectionHeaders } from '../../../constants/headers'
import { HeaderCell } from '../../tableTemplates/headerCell'
import StandardButton from '../../shared/standardButton'
import CreateAnalystButton from '../createAnalystButton'
import { useSampleCollectionContext, useSampleCollectionApi } from '../../../contexts/sampleCollectionContext'
import ImportModal from './importModal'
import SampleCollectionRow from './sampleCollectionRow'
import LoadingSpinner from '../../shared/loadingSpinner'

const SampleCollectionTable = () => {
  const { collections, importModal, loading } = useSampleCollectionContext()
  const { toggleField } = useSampleCollectionApi()
  return (
    <>
      <div className={`w-full px-2 py-4 bg-gray-200 flex justify-center`}>
        <h2 className="text-2xl font-bold">Sample Collection Results</h2>
      </div>
      {loading && <LoadingSpinner />}
      {!loading && <div className="flex flex-col lg:flex-row w-full">
        <div className='grid grid-cols-11 mx-3 my-3'>
          {sampleCollectionHeaders.map((header, index) => (
            <HeaderCell key={index} title={header.title} />
          ))}
          {collections.map((collection, index) => (
            <SampleCollectionRow key={index} collection={collection} />
          ))}
          <div className='col-span-11 p-2 text-center content-center border border-gray-300'>
            <CreateAnalystButton />
            <StandardButton buttonText={'Upload New Test Results'} onClickHandler={() => toggleField('importModal')} />
          </div>
          {importModal && <ImportModal />}
        </div>
      </div>
      }
    </>
  )
}

export default SampleCollectionTable
